
import router from "./../router";
import {
  defineComponent,
  reactive,
  ref,
  UnwrapRef,
  onMounted,
  createVNode,
} from "vue";
import { useRoute } from "vue-router";
import { notification, Modal, UploadProps } from "ant-design-vue";
import { CheckCircleFilled, HeartOutlined, UploadOutlined } from "@ant-design/icons-vue";
import { ValidateErrorEntity } from "ant-design-vue/es/form/interface";
import validate from "../services/validator";
import moment, { Moment } from "moment";
import candidateService from "../services/candidate";

interface IrefereePersonalDetails {
  first_name: string;
  last_name: string;
  company: string;
  email: string;
  candidate_job: string;
  workstartmonth: string;
  workstartyear: any;
  workendmonth: string;
  workendyear: any;
}

interface IrefereeAdditionalDetails {
  referee_job: string;
  phone: string;
  referee_relationship: string;
}

export default defineComponent({
  props: ["AccessToken", "From"],
  components: {
    CheckCircleFilled,
    HeartOutlined,
    UploadOutlined
  },
  setup(props, { emit }) {
    const route = useRoute();
    const refereDetails = ref();
    const introPage = ref();
    const pageLoader = ref<boolean>(false);
    const accessToken = ref();
    const activeKey = ref([]);
    const activeTab = ref('1');
    const questionPage = ref();
    const thanksPage = ref();
    const editRefereeDetails = ref();
    const saverefereLoading = ref<boolean>(false);
    const saveAnswerLoading = ref<boolean>(false);
    const profiles = ref();
    const allQuestions = ref();
    const answermodal = ref();
    const openedFrom = ref();
    const candidateDetails = ref();
    const pageLoaded = ref<boolean>(false);
    const showForm = ref<boolean>(false);
    const refereeToken = ref();
    const refereeId = ref();
    const startYearOpen = ref<boolean>(false);
    const endYearOpen = ref<boolean>(false);
    // const gComments = ref([]);
    const fileList = ref([]);
    // const fileLists: Record<string, any[]> = {};
    const uploadUrl = ref();
    const newUnknownUser = ref<boolean>(true);
    const getRefereeDetails = async (id: any) => {
      pageLoader.value = true;
      try {
        const data = {
          url_token: id,
        };
        const res = await candidateService.getWebRefereeDetails(data);
        if (res.data.data.success) {
          refereDetails.value = res.data.data.referee;
          if (refereDetails.value == null) {
            introPage.value = false;
            showForm.value = false;
          } else {
            introPage.value = true;
            showForm.value = true;
            refereDetails.value.candidate.candidate_name =
              refereDetails.value.candidate.candidate_name.toUpperCase();
          }
          pageLoaded.value = true;
        }
        pageLoader.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          pageLoader.value = false;
        }
      }
    };
    const getCandidateId = async (id: any) => {
      pageLoader.value = true;
      try {
        const data = {
          url_token: id,
        };
        const res = await candidateService.getWebCandidateId(data);
        if (res.data.data.success) {
          candidateDetails.value = res.data.data.candidate;
          if (res.data.data.add_refree === true) {
            introPage.value = false;
            questionPage.value = false;
            showForm.value = true;
            candidateDetails.value.candidate_name =
              candidateDetails.value.candidate_name.toUpperCase();
          } else {
            showForm.value = false;
          }
          pageLoaded.value = true;
        }
        pageLoader.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          pageLoader.value = false;
        }
      }
    };
    const completeReference = async () => {
      introPage.value = false;
      questionPage.value = false;
      setModalValue();
      refereeAdditionalModal.referee_job = refereDetails.value.referee_job;
      refereeAdditionalModal.phone = refereDetails.value.phone;
      refereeAdditionalModal.referee_relationship =
        refereDetails.value.referee_relationship;
    };
    const refereePersonalFormRef = ref();
    const refereePersonalRules = validate.webRefereeValidation;
    const refereePersonalModal: UnwrapRef<IrefereePersonalDetails> = reactive({
      first_name: "",
      last_name: "",
      company: "",
      candidate_job: "",
      email: "",
      workstartmonth: "",
      workstartyear: "",
      workendmonth: "",
      workendyear: "",
    });
    const setModalValue = async () => {
      refereePersonalModal.first_name = refereDetails.value.first_name;
      refereePersonalModal.last_name = refereDetails.value.last_name;
      refereePersonalModal.company = refereDetails.value.company;
      refereePersonalModal.candidate_job = refereDetails.value.candidate_job;
      refereePersonalModal.email = refereDetails.value.email;
      refereePersonalModal.workstartmonth = moment(
        refereDetails.value.work_start,
        "YYYY-MM-DD"
      ).format();
      refereePersonalModal.workstartyear = moment(
        refereDetails.value.work_start,
        "YYYY-MM-DD"
      ).format();
      if (refereDetails.value.work_end) {
        refereePersonalModal.workendmonth = moment(
          refereDetails.value.work_end,
          "YYYY-MM-DD"
        ).format();
        refereePersonalModal.workendyear = moment(
          refereDetails.value.work_end,
          "YYYY-MM-DD"
        ).format();
      }
    };
    // const disabledStartDate = (startValue: any) => {
    //   const endValue = refereePersonalModal.workend;
    //   if (!startValue || !endValue) {
    //     return false;
    //   }
    //   return startValue.valueOf() >= endValue.valueOf();
    // };
    // const disabledEndDate = (endValue: any) => {
    //   const startValue = refereePersonalModal.workstart;
    //   if (!endValue || !startValue) {
    //     return false;
    //   }
    //   return startValue.valueOf() >= endValue.valueOf();
    // };
    const editRefereeDetailsInfo = async () => {
      editRefereeDetails.value = true;
    };
    const cancleRefereeDetails = async () => {
      editRefereeDetails.value = false;
      refereePersonalFormRef.value.clearValidate();
      setModalValue();
    };
    const saveRefereeDetails = async () => {
      refereePersonalFormRef.value
        .validate()
        .then(() => {
          editRefereeDetails.value = false;
        })
        .catch((error: ValidateErrorEntity<IrefereePersonalDetails>) => {
          console.log("error", error);
        });
    };
    const refereeAdditionalFormRef = ref();
    const refereeAdditionalRules = validate.webRefereeAdditionalValidation;
    const refereeAdditionalModal: UnwrapRef<IrefereeAdditionalDetails> =
      reactive({
        referee_job: "",
        phone: "",
        referee_relationship: "",
      });
    const notify = async (
      message: string,
      description: string,
      type: string
    ) => {
      (notification as any)[type]({
        message,
        description,
      });
    };
    const saveRefereeAdditionalDetails = async (data: any) => {
      refereePersonalFormRef.value
        .validate()
        .then(() => {
          refereeAdditionalFormRef.value
            .validate()
            .then(async () => {
              data.referee_relationship =
                refereeAdditionalModal.referee_relationship;
              data.phone = refereeAdditionalModal.phone;
              data.referee_job = refereeAdditionalModal.referee_job;
              data.url_token = accessToken.value;
              const d1d = "01";
              const d1M = moment(data.workstartmonth).format("MM");
              const d1y = moment(data.workstartyear).format("YYYY");
              data.work_start = d1y + "-" + d1M + "-" + d1d;
              const d2d = "10";
              const d2M = moment(data.workendmonth).format("MM");
              const d2y = moment(data.workendyear).format("YYYY");
              data.work_end = d2y + "-" + d2M + "-" + d2d;
              saverefereLoading.value = true;
              try {
                if (props.From === "refereePage") {
                  data.id = refereDetails.value.id;
                  const res = await candidateService.updateWebRefereeDetails(
                    data
                  );
                  if (res.data.data.success) {
                    await notify("Success", res.data.data.message, "success");
                    questionPage.value = true;
                    activeKey.value = ["2"];
                    getQuestions();
                  }
                } else {
                  if (newUnknownUser.value == true) {
                    data.candidate_id = candidateDetails.value.id;
                    data.referee_web_page = 1;
                    const res = await candidateService.webCandidateAddReferee(
                      data
                    );
                    if (res.data.data.success) {
                      newUnknownUser.value = false;
                      await notify("Success", res.data.data.message, "success");
                      questionPage.value = true;
                      activeKey.value = ["2"];
                      refereeToken.value = res.data.data.referee_url_token;
                      pageLoader.value = true;
                      refereeId.value = res.data.data.referee_id;
                      getQuestions();
                    }
                  } else {
                    delete data.candidate_id;
                    delete data.referee_web_page;

                    data.id = refereeId.value;
                    data.url_token = refereeToken.value;
                    const res = await candidateService.updateWebRefereeDetails(
                      data
                    );
                    if (res.data.data.success) {
                      await notify("Success", res.data.data.message, "success");
                      questionPage.value = true;
                      activeKey.value = ["2"];
                      getQuestions();
                    }
                  }
                }
                saverefereLoading.value = false;
              } catch (error: any) {
                if (error.response) {
                  console.log("Error", error.response);
                  if (error.response.data.work_end) {
                    await notify(
                      "Error",
                      error.response.data.work_end[0],
                      "error"
                    );
                  } else {
                    await notify("Error", error.response.data.message, "error");
                  }
                }
                saverefereLoading.value = false;
              }
            })
            .catch((error: ValidateErrorEntity<IrefereeAdditionalDetails>) => {
              console.log("error", error);
            });
        })
        .catch((error: ValidateErrorEntity<IrefereePersonalDetails>) => {
          console.log("error", error);
        });
    };
    const getQuestions = async () => {
      pageLoader.value = true;
      try {
        const data = {
          url_token:
            props.From === "refereePage"
              ? accessToken.value
              : refereeToken.value,
          id:
            props.From === "refereePage"
              ? refereDetails.value.id
              : refereeId.value,
        };
        const res = await candidateService.getWebRefereeQuestions(data);
        if (res.data.data.success) {
          for (const profile of res.data.data.profiles) {
            for (const question of profile.questions) {
              if (question.question_type == "Rating") {
                answermodal.value = Object.assign({}, answermodal.value, {
                  [question.id]: 0,
                });
              } else {
                answermodal.value = Object.assign({}, answermodal.value, {
                  [question.id]: "",
                });
              }
            }
            // var e;
            let index = profile.questions.findIndex((e:any) => e.question === 'Any further comments?');
            // e = profile.questions.splice(index,1);
            // let count = profile.questions.length - 1;
            // profile.questions.push(profile.questions.splice(count, 0, e));
            if(index == 0) {
              profile.questions.push(profile.questions.shift());
            }
          }
          // allQuestions.value = res.data.data.questions;
          profiles.value =  res.data.data.profiles;
        }
        pageLoader.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          pageLoader.value = false;
        }
      }
    };
    const validateallFields = async () => {
      let validate = true;
      for (const profile of profiles.value) {
        for (const question of profile.questions) {
          if (question.is_required == 1) {
            const filtered = Object.keys(answermodal.value)
              .filter((key) => key.includes(question.id))
              .reduce((obj: any, key: any) => {
                obj[key] = answermodal.value[key];
                return obj;
              }, {});
            if (Object.values(filtered)[0] == "") {
              validate = false;
            }
          }
        }
      }
      return validate;
    };
    const modifyFields = async () => {
      for (const profile of profiles.value) {
        for (const question of profile.questions) {
          if (question.field_type == "multiple") {
            const filtered = Object.keys(answermodal.value)
              .filter((key) => key.includes(question.id))
              .reduce((obj: any, key: any) => {
                obj[key] = answermodal.value[key];
                return obj;
              }, {});
            if (Object.values(filtered)[0] != "") {
              if (question.question_type != "Checkbox") {
                answermodal.value[question.id] = [Object.values(filtered)[0]];
              }
            } else {
              delete answermodal.value[question.id];
            }
          }
        }
      }
    };
    const saveAnswers = async () => {
      const isvalid = await validateallFields();
      if (isvalid == false) {
        await notify("Error", "Please enter all mandatory fields", "error");
      } else {
        await modifyFields();
        saveAnswerLoading.value = true;
        const data = {
          url_token:
            props.From === "refereePage"
              ? accessToken.value
              : refereeToken.value,
          id:
            props.From === "refereePage"
              ? refereDetails.value.id
              : refereeId.value,
          answers: answermodal.value,
          // comments: gComments.value,
        };
        try {
          const res = await candidateService.saveWebRefereeAnswers(data);
          if (res.data.data.success) {
            await notify("Success", res.data.data.message, "success");
            thanksPage.value = true;
            activeKey.value = ["3"];
          }
          saveAnswerLoading.value = false;
        } catch (error: any) {
          if (error.response) {
            console.log(error.response);
            await notify("Error", error.response.data.data.message, "error");
            saveAnswerLoading.value = false;
          }
        }
      }
    };
    const closewindow = async () => {
      // document.getElementsByTagName('html')[0].remove();
      // window.open('', '_self', '');
      window.open("about:blank", "_self");
      window.close();
      // self.close();
      // activeKey.value = ["1"];
      // questionPage.value = false;
      // thanksPage.value = false;
    };
    const tabChanged = async (value: any) => {
      if (value === "1") {
        questionPage.value = false;
        thanksPage.value = false;
        if (props.From === "refereePage") {
          pageLoader.value = true;
          try {
            const data = {
              url_token: props.AccessToken,
            };
            const res = await candidateService.getWebRefereeDetails(data);
            if (res.data.data.success) {
              refereDetails.value = res.data.data.referee;
              if (refereDetails.value == null) {
                introPage.value = false;
                showForm.value = false;
              } else {
                introPage.value = false;
                showForm.value = true;
              }
              pageLoaded.value = true;
            }
            pageLoader.value = false;
          } catch (error: any) {
            if (error.response) {
              console.log(error.response);
              pageLoader.value = false;
            }
          }
        }
      }
      if (value === "2") {
        questionPage.value = true;
        thanksPage.value = false;
      }
    };
    const handlePanelChange1 = (val: Moment[], mode: string) => {
      refereePersonalModal.workstartyear = val;
      startYearOpen.value = false;
      setTimeout(() => {
        refereePersonalFormRef.value.validate("workstartyear");
      }, 100);
    };
    const getfocus1 = () => {
      startYearOpen.value = true;
    };
    const handlePanelChange2 = (val: Moment[], mode: string) => {
      refereePersonalModal.workendyear = val;
      endYearOpen.value = false;
      setTimeout(() => {
        refereePersonalFormRef.value.validate("workendyear");
      }, 100);
    };
    const getfocus2 = () => {
      endYearOpen.value = true;
    };
    const handleChange = (info: any, id: any) => {
      if (info.file.status === "error") {
        notify("Error", info.file.response.file[0], "error");
      }      
      
      if (info.file.status === "done") {
        if (info.file.response) {
          if (info.file.response.data.success) {            
            answermodal.value = Object.assign({}, answermodal.value, {
                  [id]: info.file.response.data.image_url,
                });
            let fileLists = [...info.fileList];
            fileLists = fileLists.slice(-1);
            fileLists = fileLists.map(file => {
              if (file.response) {
                file.url = file.response.data.image_url;
              }
              return file;
            });
            // fileList.value = fileLists;
            fileList.value = Object.assign({}, fileList.value, {
                  [id]: fileLists,
                });
            // if (!fileList[id]) {
            //   fileList[id] = [];
            // }

            // // Push the file to the corresponding file list
            // fileList[id].push(fileLists);
          }
        }
      }
      if (info.file.status === "removed") {         
        answermodal.value = Object.assign({}, answermodal.value, {
              [id]: '',
            });
        fileList.value = Object.assign({}, fileList.value, {
              [id]: '',
            });
      }

    };
    const beforeUpload = (file: any, id: string) => {
      const isLt2M = file.size / 1024 / 1024 < 2;
      // const allowedExtensions = ['pdf', 'doc', 'docx', 'ppt', 'pptx', 'txt', 'jpg', 'jpeg', 'png'];

      if (!isLt2M) {
        notify("Error", "File must smaller than 2MB!", "error");
        return false;
      }
      // const extension = file.name.split('.').pop()?.toLowerCase();

      // if (!extension || !allowedExtensions.includes(extension)) {
      //   notify("Error", "Invalid file type. Allowed types: pdf, doc, docx, ppt, pptx, txt, jpg, jpeg, png", "error");
      //   fileList.value = Object.assign({}, fileList.value, {
      //         [id]: '',
      //       });
      //   return false;
      // }
      return isLt2M;
    };
    const handleRemove = (id: any) => {
      console.log('id', id)
      answermodal.value = Object.assign({}, answermodal.value, {
        [id]: '',
      });
    };
    onMounted(async () => {
      accessToken.value = props.AccessToken;
      openedFrom.value = props.From;
      showForm.value = true;
      if (props.From === "refereePage") {
        getRefereeDetails(props.AccessToken);
        editRefereeDetails.value = false;
        uploadUrl.value = process.env.VUE_APP_API_URL + "upload-document?referee=1&url_token="+props.AccessToken;
      } else {
        getCandidateId(props.AccessToken);
        editRefereeDetails.value = true;
        uploadUrl.value = process.env.VUE_APP_API_URL + "upload-document?candidate=1&url_token="+props.AccessToken;
      }
      activeKey.value = ["1"];
      thanksPage.value = false;      
    });
    return {
      startYearOpen,
      endYearOpen,
      handlePanelChange1,
      handlePanelChange2,
      getfocus1,
      getfocus2,
      accessToken,
      refereDetails,
      pageLoader,
      moment,
      completeReference,
      introPage,
      activeKey,
      activeTab,
      questionPage,
      refereePersonalFormRef,
      refereePersonalRules,
      refereePersonalModal,
      // disabledStartDate,
      // disabledEndDate,
      editRefereeDetailsInfo,
      editRefereeDetails,
      cancleRefereeDetails,
      saveRefereeDetails,
      refereeAdditionalFormRef,
      refereeAdditionalRules,
      refereeAdditionalModal,
      saveRefereeAdditionalDetails,
      tabChanged,
      notify,
      saveAnswers,
      thanksPage,
      saverefereLoading,
      saveAnswerLoading,
      closewindow,
      profiles,
      allQuestions,
      answermodal,
      // gComments,
      openedFrom,
      candidateDetails,
      pageLoaded,
      showForm,
      refereeId,
      newUnknownUser,
      labelCol: {
        md: {
          span: 14,
        },
        sm: {
          span: 24,
        },
      },
      wrapperCol: {
        md: {
          span: 10,
        },
        sm: {
          span: 24,
        },
      },
      handleChange,
      fileList,
      beforeUpload,
      uploadUrl
    };
  },
  created: function () {
    this.moment = moment;
  },
});
